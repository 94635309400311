var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "px-4" },
    [
      _c(
        "v-radio-group",
        {
          attrs: { disabled: _vm.numInvitationsToSend !== 0 },
          model: {
            value: _vm.invitationType,
            callback: function ($$v) {
              _vm.invitationType = $$v
            },
            expression: "invitationType",
          },
        },
        _vm._l(_vm.invitationTypes, function (mode) {
          return _c(
            "div",
            { key: mode.type },
            [
              _c("v-radio", {
                staticClass: "mt-3 caption",
                attrs: {
                  color: "primary",
                  value: mode.value,
                  label: mode.type,
                },
              }),
              _c(
                "span",
                {
                  class:
                    _vm.invitationType === mode.value
                      ? "caption secondary--text"
                      : "caption",
                },
                [_vm._v(_vm._s(mode.description))]
              ),
            ],
            1
          )
        }),
        0
      ),
      _vm.invitationType
        ? _c(
            "v-form",
            {
              ref: "form",
              staticClass: "mt-3",
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c("h4", { staticClass: "secondary--text" }, [
                _vm._v("Email addresses"),
              ]),
              _c("v-textarea", {
                attrs: {
                  rules: _vm.rules.nonEmpty,
                  required: "",
                  rows: "3",
                  "auto-grow": "",
                  disabled: _vm.numInvitationsToSend !== 0,
                  outlined: "",
                },
                on: { input: _vm.updateEmailString },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "label",
                      fn: function () {
                        return [_vm._v("Enter user emails")]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  4181440253
                ),
                model: {
                  value: _vm.emailsInput,
                  callback: function ($$v) {
                    _vm.emailsInput = $$v
                  },
                  expression: "emailsInput",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.emailsInput.length
        ? _c("div", { staticClass: "mt-3 secondary--text" }, [
            _c("h3", [
              _vm._v(
                " The following users will receive an invitation email to become "
              ),
              _vm.invitationType === "orgManagers"
                ? _c("span", [_vm._v("managers")])
                : _vm._e(),
              _vm.invitationType === "orgFaculties"
                ? _c("span", [_vm._v("faculty members")])
                : _vm._e(),
              _vm._v(" of this organization. "),
            ]),
            _c(
              "div",
              _vm._l(_vm.emails, function (email, index) {
                return _c("ul", { key: index, staticClass: "mt-3" }, [
                  email.status === _vm.emailValidationOutcomes.LOW_RISK_EMAIL ||
                  email.status ===
                    _vm.emailValidationOutcomes.UNKNOWN_RISK_EMAIL
                    ? _c(
                        "li",
                        [
                          _vm._v(" " + _vm._s(email.email) + " "),
                          _c(
                            "v-chip",
                            {
                              staticClass: "ml-1",
                              attrs: { "x-small": "", color: "success" },
                            },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-1", attrs: { small: "" } },
                                [_vm._v("check_circle")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-uppercase font-weight-bold",
                                },
                                [_vm._v("validated")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : email.status ===
                      _vm.emailValidationOutcomes.HIGH_RISK_EMAIL
                    ? _c(
                        "li",
                        [
                          _vm._v(" " + _vm._s(email.email) + " "),
                          _c(
                            "v-chip",
                            {
                              staticClass: "ml-1",
                              attrs: { "x-small": "", color: "error" },
                            },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-1", attrs: { small: "" } },
                                [_vm._v("cancel")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-uppercase font-weight-bold",
                                },
                                [_vm._v("validation failed")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : email.status ===
                      _vm.emailValidationOutcomes.UNVALIDATED_EMAIL
                    ? _c(
                        "li",
                        [
                          _vm._v(" " + _vm._s(email.email) + " "),
                          _c(
                            "v-chip",
                            {
                              staticClass: "ml-1",
                              attrs: { "x-small": "", color: "secondary" },
                            },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-1", attrs: { small: "" } },
                                [_vm._v("warning")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-uppercase font-weight-bold",
                                },
                                [_vm._v("validation error")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm.validatingEmails
                    ? _c(
                        "li",
                        [
                          _vm._v(" " + _vm._s(email.email) + " "),
                          _c("v-progress-circular", {
                            staticClass: "ml-1",
                            attrs: {
                              size: 20,
                              indeterminate: "",
                              color: "primary",
                            },
                          }),
                        ],
                        1
                      )
                    : _c("li", [_vm._v(_vm._s(email.email))]),
                ])
              }),
              0
            ),
          ])
        : _vm._e(),
      _c("InvitationErrorsDialog", {
        attrs: {
          showDialog: _vm.showInvitationErrorsDialog,
          errorType: _vm.validationErrorType,
          invalidEmails: _vm.invalidEmailsList,
        },
        on: {
          proceedWithTheInvitation: function ($event) {
            return _vm.inviteUsers($event.value)
          },
          validateEmailsAgain: function ($event) {
            return _vm.validateEmailsAndInviteUsers($event.value)
          },
        },
      }),
      _c(
        "div",
        { staticClass: "d-flex align-center justify-center flex-column w-100" },
        [
          _vm.userInfo && _vm.userInfo.is_sysadmin
            ? _c("v-checkbox", {
                staticClass: "mb-1 mt-5 pa-0",
                attrs: { "hide-details": "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "d-flex align-center" },
                            [
                              _vm._v(
                                " Generate invitations but don't send emails "
                              ),
                              _c(
                                "v-chip",
                                {
                                  staticClass: "ml-1",
                                  attrs: {
                                    color: "info",
                                    label: "",
                                    outlined: "",
                                    "x-small": "",
                                  },
                                },
                                [_vm._v("Sysadmin")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  1335303574
                ),
                model: {
                  value: _vm.generateInvitationLinksOnly,
                  callback: function ($$v) {
                    _vm.generateInvitationLinksOnly = $$v
                  },
                  expression: "generateInvitationLinksOnly",
                },
              })
            : _vm._e(),
          _c(
            "v-btn",
            {
              staticClass: "mr-1",
              attrs: {
                loading: _vm.numInvitationsToSend > 0 || _vm.validatingEmails,
                disabled:
                  !_vm.valid ||
                  _vm.numInvitationsToSend !== 0 ||
                  _vm.validatingEmails,
                color: "primary",
              },
              on: {
                click: function ($event) {
                  return _vm.validateEmailsAndInviteUsers(true)
                },
              },
            },
            [
              _c("v-icon", { attrs: { small: "" } }, [_vm._v("add")]),
              _vm._v(" Invite "),
            ],
            1
          ),
          _vm.emailsValidationError || _vm.backendError
            ? _c(
                "v-alert",
                {
                  staticClass: "mt-4",
                  attrs: { color: "error", icon: "warning", text: "" },
                },
                [
                  _c("div", { staticClass: "d-flex flex-column" }, [
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v("Email Validation Error"),
                    ]),
                    _vm._v(
                      " User invitation has failed because one or more emails could not be validated, please try again later. "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.foundInvalidEmails
            ? _c(
                "v-alert",
                {
                  staticClass: "mt-4",
                  attrs: { type: "warning", icon: "warning", text: "" },
                },
                [
                  _c("div", { staticClass: "d-flex flex-column" }, [
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v("Invalid Emails"),
                    ]),
                    _vm._v(
                      " One or more of the provided emails might be invalid. "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.error
            ? _c(
                "v-alert",
                {
                  staticClass: "mt-4",
                  attrs: { color: "error", icon: "warning", text: "" },
                },
                [
                  _c("div", { staticClass: "d-flex flex-column" }, [
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v("Invitation Failure Error"),
                    ]),
                    _vm._v(" Failed to invite the following users "),
                    _c(
                      "ul",
                      _vm._l(_vm.failedInvitations, function (email, index) {
                        return _c("li", { key: index }, [
                          _vm._v(" " + _vm._s(email) + " "),
                        ])
                      }),
                      0
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }